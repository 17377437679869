
export enum Format {
    freq= "Частота",
    rank = "Ранг"
}

export enum FormatForApi{
    freq= "freq",
    rank = "rank"
}

export enum AxisFormat {
    linear = "linear",
    log = "log"
}

export enum AxisFormatRu {
    linear = "Линейный",
    log = "Логарифмический"
}