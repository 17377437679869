import React from "react";
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CloseIcon from '@material-ui/icons/Close';
import {
    Button,
    DialogActions,
    DialogContent,
    IconButton,
    MenuItem,
    Popover,
    TextField,
    Typography,
} from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Chip from '@material-ui/core/Chip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {AxisFormat, AxisFormatRu, Format, FormatForApi} from "./types";
import {httpClient} from "../../api/api";

const styles = (theme: Theme) => createStyles({
    margin: {
        marginLeft: theme.spacing(2),
    },
    chip: {
        maxWidth: 200,
        height: "4vh",
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(2),
        fontSize: "small",
        fontWeight: "bold",
        fontFamily: "arial",
    },
    chipGrid: {
        marginLeft: theme.spacing(2),
    },
    dialog: {
        position: "absolute", left: theme.spacing(5), top: theme.spacing(30),
    },
    text: {
        marginLeft: theme.spacing(1),
        width: 100,
        marginRight: theme.spacing(1)
    },
    mainInput: {
        height: "5vh"
    }
});

interface InputProps extends WithStyles <typeof styles> {
    callback: any
    errorCallback: any
    lemCallback: any
}

interface InputState {
    openYearDialog: boolean
    openAxisFormatMenu: boolean
    openFormatMenu: boolean
    openHelper: boolean
    inputText: string
    startYear: number
    endYear: number
    axisFormat: AxisFormat,
    format: Format
}

class Input extends React.Component<InputProps, InputState> {

    constructor(props: InputProps) {
        super(props);
        this.openYearDialog = this.openYearDialog.bind(this)
        this.closeYearDialog = this.closeYearDialog.bind(this)
        this.changeStart = this.changeStart.bind(this)
        this.changeEnd = this.changeEnd.bind(this)
        this.clickClose = this.clickClose.bind(this)
        this.changeInputText = this.changeInputText.bind(this)
        this.openHelper = this.openHelper.bind(this)
        this.closeHelper = this.closeHelper.bind(this)
        this.openAxisMenu = this.openAxisMenu.bind(this)
        this.closeAxisMenu = this.closeAxisMenu.bind(this)
        this.clickSearch = this.clickSearch.bind(this)
        this.onKeyEnterDown = this.onKeyEnterDown.bind(this)
        this.openFormatMenu = this.openFormatMenu.bind(this)
        this.closeFormatMenu = this.closeFormatMenu.bind(this)
        this.closeYearDialogAndConfirm = this.closeYearDialogAndConfirm.bind(this)
        this.addLemmas = this.addLemmas.bind(this)
    }

    state = {
        openYearDialog: false,
        openAxisFormatMenu: false,
        openFormatMenu: false,
        openHelper: false,
        inputText: "и, как",
        startYear: 1800,
        endYear: 2000,
        axisFormat: AxisFormat.linear,
        format: Format.freq,
    }

    //стирвет значение в строке вводв
    clickClose() {
        this.setState({inputText: ""})
    }

    //преобразует инпут в форму для бека - передает в класс
    clickSearch() {
        let out_array = []
        const str_array = this.state.inputText.split(',')
        for (let str of str_array) {
            let out_inner = []
            for (let w of str.split('+')){
                out_inner.push(w.replace(/\s/g, ""))
            }
            out_array.push({
                inner_words: out_inner
            })
        }

        let format = FormatForApi.rank

        if (this.state.format === Format.freq) {
            format = FormatForApi.freq
        }

        this.props.callback(
            out_array, this.state.axisFormat, this.state.startYear, this.state.endYear, format, str_array
        )
    }

    //ловит событие - нажатие кнопки энтер в строке ввода
    onKeyEnterDown(e: any) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.clickSearch()
        }
    }

    //срабатывает на изменение текста
    changeInputText(e: any) {
        this.props.errorCallback("")
        this.props.lemCallback([])
        this.setState({inputText: e.target.value.toString().toLowerCase()})
    }

    //срабатывает на нажатие знака вопроса
    openHelper() {
        this.setState({openHelper: true})
    }

    closeHelper() {
        this.setState({openHelper: false})
    }

    //срабатывает на открытие временного промежутка
    openYearDialog() {
        this.setState({openYearDialog: true})
    }

    closeYearDialog() {
        this.setState({openYearDialog: false})
    }

    //срабатывает на открытие типа осей
    openAxisMenu() {
        this.setState({openAxisFormatMenu: true})
    }

    closeAxisMenu() {
        this.setState({openAxisFormatMenu: false})
    }

    //срабатывает на открытие типа данных
    openFormatMenu() {
        this.setState({openFormatMenu: true})
    }

    closeFormatMenu() {
        this.setState({openFormatMenu: false})
    }

    //кнопка подтверждения выбора временного промежутка
    closeYearDialogAndConfirm() {
        this.clickSearch()
        this.setState({openYearDialog: false})
    }

    //изменяет начальное время
    changeStart(e: any) {
        this.setState({startYear: e.target.value})
    }

    changeEnd(e: any) {
        this.setState({endYear: e.target.value})
    }

    //кнопка - добавить леммы
    addLemmas() {
        if (this.state.inputText.split(",").length === 1 && !this.state.inputText.includes("+")) {
            this.getWorldsLem()
            this.searchLemmas()
        } else {
            this.props.errorCallback("Функция доступна только для одной словоформы")
        }
    }

    //поиск лемм (это -> это+этого+этому, этой+..)
    getWorldsLem() {
        httpClient.post("/api/lemmas", {
            words: [this.state.inputText.replace(/\s/g, "")]
        })
            .then(response => {
                this.props.lemCallback(response.data.data.words)
            })
    }

    //поиск типа лемм (NOUN..)
    searchLemmas() {
        httpClient.post("api/lemmas/words",
            {word: this.state.inputText.replace(/\s/g, "")})
            .then(response=> {
                let out_str = ""
                let data = response.data.data
                let i = 0
                if (data.lemmgroups !== null) {
                    for (let lem of data.lemmgroups) {
                        let inner_str = ""
                        let j = 0
                        for (let word of lem.words) {
                            if (j === 0) {
                                inner_str = word
                            }else {
                                inner_str = inner_str + "+" + word
                            }
                            j = j + 1

                        }
                        if (i === 0) {
                            out_str = inner_str
                        } else {
                            out_str = out_str + ", " + inner_str
                        }
                        i = i + 1

                    }
                    this.setState({inputText: out_str})
                    this.clickSearch()
                } else {
                    this.props.errorCallback("Леммы не найдены")
                }

            })
    }

    // рендеры
    renderFormatTypeMenu() {
        return (
            <Popover open={this.state.openFormatMenu}
                     onClose={this.closeFormatMenu}
                     anchorOrigin={{
                         vertical: 120,
                         horizontal: 540,
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}
            >
                <MenuItem onClick={async () => {
                    if (this.state.inputText.includes("+")) {
                        this.props.errorCallback("Функция не доступна для запросов с операцией \"+")
                    } else {
                        await this.setState({format: Format.rank, openFormatMenu: false})
                        this.clickSearch()
                    }
                }}>{Format.rank}</MenuItem>
                <MenuItem onClick={async () => {
                    await this.setState({format: Format.freq, openFormatMenu: false})
                    this.clickSearch()
                }}>{Format.freq}</MenuItem>
            </Popover>
        )
    }

    renderAxisTypeMenu() {
        return (
            <Popover open={this.state.openAxisFormatMenu}
                     onClose={this.closeAxisMenu}
                     anchorOrigin={{
                         vertical: 120,
                         horizontal: 410,
                     }}>
                <MenuItem onClick={async () => {
                    await this.setState({axisFormat: AxisFormat.linear, openAxisFormatMenu: false})
                    this.clickSearch()
                }}>{AxisFormatRu.linear}</MenuItem>
                <MenuItem onClick={async () => {
                    await this.setState({axisFormat: AxisFormat.log, openAxisFormatMenu: false})
                    this.clickSearch()
                }}>{AxisFormatRu.log}</MenuItem>
            </Popover>
        )
    }

    renderHelper() {
        return (
            <Popover
                open={this.state.openHelper}
                onClose={this.closeHelper}
                anchorOrigin={{
                    vertical: 80,
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <DialogContent>
                    <Typography variant="subtitle1">
                        Поиск одной или нескольких словоформ,
                    </Typography>
                    <Typography variant="subtitle1">
                        разделенных запятой или соединительным знаком "+"
                    </Typography>
                </DialogContent>
            </Popover>
        )
    }

    renderYearDialog() {
        const classes = this.props.classes
        return (
            <Popover
                anchorOrigin={{
                    vertical: 120,
                    horizontal: 300,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={this.state.openYearDialog}
                onClose={this.closeYearDialog}>

                <DialogContent>
                    <Typography variant="subtitle1">
                        Выберерете промежуток
                    </Typography>
                    <TextField type="number" defaultValue={this.state.startYear} className={classes.text} size={"small"}
                               variant="outlined" onChange={this.changeStart}/>
                    -
                    <TextField type="number" defaultValue={this.state.endYear} className={classes.text} size={"small"}
                              onChange={this.changeEnd} variant="outlined"/>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.closeYearDialogAndConfirm}>Подтвердить</Button>
                    <Button color="secondary" onClick={this.closeYearDialog}>Закрыть</Button>
                </DialogActions>
            </Popover>
        )

    }


    render() {
        const classes = this.props.classes
        return (
            <>
                {this.renderYearDialog()}
                {this.renderHelper()}
                {this.renderAxisTypeMenu()}
                {this.renderFormatTypeMenu()}
                <Grid item xs={10}>
                    <FormControl className={classes.margin} fullWidth={true}>
                        <OutlinedInput
                            onKeyDown={this.onKeyEnterDown}
                            placeholder="Искать"
                            className={classes.mainInput}
                            value={this.state.inputText}
                            onChange={this.changeInputText}
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton onClick={this.clickSearch}>
                                        <SearchIcon/>
                                    </IconButton>

                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onClick={this.clickClose}>
                                        <CloseIcon/>
                                    </IconButton>
                                    <IconButton onClick={this.openHelper}>
                                        <HelpOutlineIcon/>
                                    </IconButton>
                                </InputAdornment>

                            }
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={10} className={classes.chipGrid}>
                    <Chip
                        className={classes.chip}
                        deleteIcon={<KeyboardArrowDownIcon/>}
                        label={this.state.startYear.toString() + " - " + this.state.endYear.toString()}
                        color="primary"
                        clickable
                        onDelete={this.openYearDialog}
                        onClick={this.openYearDialog}
                    />
                    <Chip
                        className={classes.chip}
                        deleteIcon={<KeyboardArrowDownIcon/>}
                        label={AxisFormatRu[this.state.axisFormat]}
                        color="primary"
                        clickable
                        onDelete={this.openAxisMenu}
                        onClick={this.openAxisMenu}
                    />

                    <Chip
                        className={classes.chip}
                        deleteIcon={<KeyboardArrowDownIcon/>}
                        label={this.state.format}
                        color="primary"
                        clickable
                        onDelete={this.openFormatMenu}
                        onClick={this.openFormatMenu}
                    />

                    <Chip
                        className={classes.chip}
                        label = {"Добавить леммы"}
                        color="primary"
                        clickable
                        onClick={this.addLemmas}
                    />
                </Grid>
            </>

        );
    }
}

export default withStyles(styles)(Input)
