import React from "react";
import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core/styles";
import {Divider, Grid, Typography} from "@material-ui/core";
import Input from "../../components/inputs/inputs";
import {Chart} from "../../components/chart /chart";
import {AxisFormat, FormatForApi} from "../../components/inputs/types";
import kpfuu from "../../images/kpfuu.png"
import {Alert} from "@material-ui/lab";



const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 0,
    },
    text: {
        marginLeft: theme.spacing(10),
        marginTop: 20,
        marginBottom: 10
    },
    divider: {
        marginTop: 5,
        height: 1,
        color: "primary",
        border: 0.5

    },
    chart: {
        marginLeft: theme.spacing(0),
    }
});

interface MainProps extends WithStyles <typeof styles>{

}

export interface InnerWords {
    inner_words: string[]
}

interface MainState{
    words: InnerWords[]
    y_axis_type: AxisFormat
    start_date: number
    end_date: number,
    format: FormatForApi,
    str_array: string[]
    errorMessage: string,
    addLemmas: boolean,
    lemWord: any[]
}

class MainPage extends React.Component<MainProps, MainState> {

    constructor(props: MainProps) {
        super(props);
        this.callback = this.callback.bind(this)
        this.errorCallback = this.errorCallback.bind(this)
        this.lemCallback = this.lemCallback.bind(this)
    }

    state = {
        words: [{inner_words: ["и"]}, {inner_words: ["как"]}],
        y_axis_type: AxisFormat.linear,
        start_date: 1800,
        end_date: 2000,
        format: FormatForApi.freq,
        str_array: ["и", "как"],
        errorMessage: "",
        addLemmas: false,
        lemWord: []
    }

    //передает основные параметры из класса inputs.tsx в класс chart.tsx
    callback(words: InnerWords[],
             y_axis_type: AxisFormat,
             start_date: number,
             end_date: number,
             format: FormatForApi,
             str_array: string[])
    {
        this.setState({
            words, y_axis_type, start_date, end_date, format, str_array
        })
    }

    //в случае неправомерных действий в классах возвращает ошибку
    errorCallback(errorStr: string) {
        this.setState({errorMessage: errorStr})
    }

    //передвет в класс chart.tsx изначальное слово для поиска свойств лемм
    lemCallback( lemWord: any[]) {
        this.setState({ lemWord: lemWord})
    }

    renderError() {
        if (this.state.errorMessage !== "") {
            return (<Alert severity="error">{this.state.errorMessage}</Alert>)
        }
    }

    render() {
        const classes = this.props.classes
        return (
            <div className={classes.root}>
                <Grid container spacing={0}>
                    <Grid item xs = {11} >
                        <Typography variant="h5" color="secondary" className={classes.text}>
                        </Typography>
                    </Grid>
                    <Grid item xs = {2} >
                        <img src={kpfuu} style={{marginLeft: 15, marginTop: -20}} width={"180vw"} height={"100vh"} alt={"kpfu"}/>
                    </Grid>
                    <Grid item xs= {9}>
                        <Input callback = {this.callback} errorCallback={this.errorCallback} lemCallback={this.lemCallback}/>
                    </Grid>
                    <Grid item xs = {12}>
                        <Divider variant="fullWidth" className={classes.divider} color="grey"/>
                    </Grid>
                    <Grid item xs = {12}>
                        {this.renderError()}
                    </Grid>
                    <Grid item xs={11} className={classes.chart}>
                        <Chart words={this.state.words}
                               y_axis_type={this.state.y_axis_type}
                               start_date={this.state.start_date}
                               end_date={this.state.end_date}
                               format={this.state.format}
                               errorCallback={this.errorCallback}
                               lemWord={this.state.lemWord}
                        />
                    </Grid>
                    <Grid item xs={11} style={{marginLeft: 100}}>
                        <Typography variant="caption">
                            <a href={'https://ruswordfrequency.online/files/Manual.pdf'} download >Инструкция по использованию модуля визуализации</a>
                        </Typography>
                        <Typography display="block" variant="caption">
                             <a href="https://kpfu.ru/diahronicheskij-chastotnyj-slovar-russkoj-leksiki.html">Электронная версия словаря для скачивания</a>
                        </Typography>
                        <Typography variant="caption" display="block">
                            <a href="https://kpfu.ru/kvantitativnye-modeli-diahronicheskih-izmenenij-i.html">Главная страница проекта </a>
                        </Typography>
                        <Typography variant="caption" display="block" style={{marginTop: 10}}>
                            Сайт создан при финансовой поддержке РФФИ, проект № 17-29-09163.
                        </Typography>
                        <Typography variant="caption" display="block">
                            © В.Д. Соловьев, В.В. Бочкарев, Ю.С. Масленникова, А.В. Шевлякова, 2021
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }

}

export default withStyles(styles)(MainPage)