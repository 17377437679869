import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const httpClient = axios.create({
    baseURL:process.env.REACT_APP_BACKEND_HOST,
});

httpClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (!error) {
        return null
    }
    if (error.response === undefined) {
        return null
    }
    let text_error = "Не найдено данных по словоформам"
    let message_error = "Введите словоформы, разделенные знаком запятой  или +"

    confirmAlert({
        title: text_error,
        message: message_error,
        buttons: []
    });

    return Promise.reject(error.response);
});

httpClient.interceptors.response.use()