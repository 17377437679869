import React from "react";
import Plot from 'react-plotly.js';
import {AxisFormat, FormatForApi} from "../inputs/types";
import {Data} from "plotly.js";
import {colorArray} from "./consts";
import {httpClient} from "../../api/api";
import {InnerWords} from "../../pages/main/main";

interface ChartProps {
    words: InnerWords[],
    y_axis_type: AxisFormat,
    start_date: number
    end_date: number
    format: FormatForApi
    errorCallback: any,
    lemWord: any[]
}

interface ChartState {
    data: Data[]
    annotations: any,
    not_found: string[],
    dictionary: Map<number, string>,
}

interface LemDict{
    id: number
    pos: string
}

export class Chart extends React.Component<ChartProps, ChartState> {
    state = {
        data: [],
        annotations: [],
        not_found: [],
        dictionary:  new Map(),
    }

    constructor(props: ChartProps) {
        super(props);
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        this.getDictionary()
        this.getData()
    }

    componentDidUpdate(prevProps: Readonly<ChartProps>) {
        if (this.props.format !== prevProps.format
            || this.props.words !== prevProps.words
            || this.props.y_axis_type !== prevProps.y_axis_type
            || this.props.end_date !== prevProps.end_date
            || this.props.start_date !== prevProps.start_date
        ) {
            this.getData()
        }
    }

    getDictionary()  {
        httpClient.get("/api/pos")
            .then(response => {
                const data: LemDict[] = response.data.data
                const result = new Map(data.map(i => [i.id, i.pos]));
                this.setState({dictionary: result})
            })
    }


    getData() {
        this.setState({not_found: []})
        httpClient.post("/api/data", {
            start_year: Number(this.props.start_date),
            end_year: Number(this.props.end_date),
            data_type: this.props.format,
            words: this.props.words,

        }).then(response => {
            let out_data: Data[] = []
            const data = response.data.data.words
            let i = 0
            let names = []

            if (this.props.lemWord.length !== 0) {
                let word_lem = this.props.lemWord[0]['lemmas']
                for (let val of word_lem) {
                    names.push(`[${val.lemmas_group}] ${val.lemmas_base_form} (${this.state.dictionary.get(val.lemmas_pos_id)})`)
                }
            }

            for (let world of data) {
                let name = ""
                if (names[i] !== undefined) {
                    name = names[i]
                }
                if (world.is_exist) {
                    out_data.push({
                            x: world.years,
                            y: world.points,
                            type: 'scatter',
                            mode: 'lines',
                            showlegend: true,
                            name: name +  world.word,
                            line: {shape: 'spline', color: colorArray[i]},
                        },
                    )
                } else  {
                    // @ts-ignore
                    this.state.not_found.push(world.word.toString())
                }
                i = i + 1
            }

            this.setState({data: out_data})
            this.renderNotFoundMessage()
        }
        )
    }


    renderNotFoundMessage() {
        if (this.state.not_found.length > 0 ) {
            this.props.errorCallback("Следующие слова не найдены:" +  this.state.not_found.toString())
        } else {
            this.props.errorCallback("")
        }
    }

    render() {
        return (
            <div>
                <Plot
                    data={
                        this.state.data
                    }
                    config={{responsive: true,
                        displayModeBar: false}}
                    layout={{
                        hovermode: 'x unified',
                        showlegend: true,
                        // annotations: this.state.annotations,
                        xaxis: {
                            title: "Год",
                            autotick: false,
                            dtick: 10,
                            ticklen: 10,
                            gridcolor: 'rgb(255,255,255)',
                            zeroline: true
                        },
                        yaxis: {
                            gridcolor: 'rgb(231,229,229)',
                            zeroline: true,
                            autotick: true,
                            ticklen: 10,
                            type: this.props.y_axis_type,
                            exponentformat: 'power',

                        },
                        legend: {
                            x: 1,
                            xanchor: 'right',
                            y: 1.3,
                            bgcolor: "transparent",
                            orientation: "h"
                        },
                        autosize: true,
                        margin: {
                            r: 300,
                        },

                    }}
                    useResizeHandler={true}
                    style={{width: "92vw", height: "70vh", marginLeft: 40 }}
                />
            </div>

        );
    }
}
