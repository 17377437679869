import React from 'react';
import './App.css';
import {createTheme, ThemeProvider} from '@material-ui/core/styles'
import {blue} from '@material-ui/core/colors'
import MainPage from "./pages/main/main";

const theme = createTheme({
  palette: {
    secondary: {
      main: blue[700]
    },
    primary: {
      main: "#E7EFFDFF",
      contrastText: blue[700],
      dark: blue[900],
      light: blue[200]
    },

  },
  typography: {
    fontSize: 14,
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});

class App extends React.Component<any, any> {
  render() {
    return (
        <ThemeProvider theme={theme}>
          <MainPage/>
        </ThemeProvider>
    );
  }
}

export default App;
